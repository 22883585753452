angular.module('sq.jobs.values', []).factory('jobValues', function () {
  var jV = {};

  // A special case, this is not in a submission / queue
  const deletion = {
    failed: 'annotationDeletionFailed',
    completed: 'annotationDeleted',
  };

  const annotation = {
    remoteUploadProgress: "remoteUploadProgress",
    submitted: 'annotationSubmitted',
    started: 'annotationStarted',
    completed: 'annotationCompleted',
    failed: 'annotationFailed',
    progress: 'annotationProgress',
  };

  const searchIndex = {
    submitted: 'indexSubmitted',
    started: 'indexStarted',
    progress: 'indexProgress',
    completed: 'indexCompleted',
    failed: 'indexFailed',
  };

  const ancestry = {
    submitted: 'ancestrySubmitted',
    started: 'ancestryStarted',
    progress: 'ancestryProgress',
    completed: 'ancestryCompleted',
    failed: 'ancestryFailed',
  };

  jV.maxNotifications = 99;

  jV.server = {
    events: { annotation, searchIndex, deletion, ancestry }
  };

  return jV;
});